@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.AccountManager {
    &-Heading {
        padding-inline: 24px;
        padding-block: 14px;
        background-color: $color-other-7;

        * {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            margin-block: 0;
        }
    }

    &-Content {
        display: flex;
        flex-wrap: wrap;
        gap: 24px;
        padding-inline: 24px;
        padding-block: 16px;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
    }

    &Item {
        min-width: 175px;

        &-Name,
        &-Phone,
        &-Email {
            display: block;
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            margin-block: 0;

            @include mobile {
                font-size: 14px;
            }
        }

        &-Email {
            color: $color-primary-50;
        }

        &-Phone {
            color: $black;
        }

        &-Name {
            font-weight: 500;
        }
    }
}
