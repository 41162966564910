@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.MyAccountOrderTableRow {
    &_isDashboardSection {
        &:hover {
            background-color: transparent;
        }
    }

    td {
        &.MyAccountOrderTableRow-OrderNr {
            color: $color-primary-50;
        }

        &.MyAccountOrderTableRow-NumberOfItems {
            text-align: center;
        }

        &.MyAccountOrderTableRow-Reorder {
            width: 40px;
            text-align: center;

            button {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-inline: auto;
            }
        }

        &.MyAccountOrderTableRow-Link {
            button {
                display: flex;
                align-items: center;
                justify-content: center;
                color: $color-primary-50;
                margin-inline-start: auto;
                font-weight: 400;
                font-size: 13px;
                line-height: 20px;

                .ChevronIcon {
                    fill: currentColor;
                    height: 12px;
                    width: 12px;
                    margin-left: 10px;
                }
            }
        }
    }
}
