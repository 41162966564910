@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.CreateAccount {
    &-CreateAccountWrapper {
        border: 1px solid $color-neutral-90;
    }

    &-CmsBlockWrapper, &-CreateAccountWrapper {
        padding: 24px;

        h3 {
            margin-top: 0;
            font-size: 20px;
            font-weight: 400;
        }
    }

    &-CmsBlockWrapper {
        li {
            color: $color-neutral-70;
            background: url('../../assets/icons/check.svg') no-repeat;
            padding-left: 30px;
            margin-bottom: 16px;
            line-height: 20px;

            &::before {
                display: none;
            }
        }
    }

    &-InnerWrapper {
        display: grid;
        grid-row-gap: 64px;

        input {
            min-width: 100%;
        }

        button {
            width: 209px;
            margin-top: 0;

            @include mobile {
                width: 100%;
            }
        }
    }

    &-CreateAccountExtraInfo {
        color: $color-other-8;
        letter-spacing: 0;
    }
}

@include mobileAndTablet {
    .CreateAccount {
        &-CreateAccountWrapper, &-CmsBlockWrapper {
            padding: 0;
            border: 0;
        }

        &-InnerWrapper {
            width: 100%;
            grid-template-columns: 1fr;

            > div {
                margin-inline: 0;
            }
        }

        .Field {
            &-HalfWidth {
                display: block;
            }

            &-Wrapper {
                &_isHalfWidth {
                    width: 100%;
                }
            }
        }
    }
}

@include tabletAndAbove {
    .CreateAccount {
        &-CmsBlockWrapper, &-CreateAccountWrapper {
            padding: 49px 68px;
        }
    }
}

@include desktopAndAbove {
    .CreateAccount {
        &-CmsBlockWrapper {
            max-width: 580px;
        }

        &-CreateAccountWrapper {
            .TypographyHeader {
                &_variant {
                    &_medium {
                        font-size: 30px;
                        line-height: 42px;
                        font-weight: 400;
                    }
                }
            }
        }
    }
}
