@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.MyAccountData {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 32px;

    .KeyValueTable {
        margin-block: 0;

        &-Heading {
            @include mobile {
                padding: 17px 24px;
            }
        }
    }

    &-AddressList {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 32px;
    }

    &-CustomerData {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 32px;

        .MyAccountCustomerTable {
            &-Actions {
                margin-block-start: 0;
            }
        }

        .KeyValueTable {
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;

            &-Heading {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;

                @include mobile {
                    padding: 18px 24px;
                }
            }

            .Field {
                margin-block: 0;
            }
        }

        .MyAccountContractorTable {
            @include mobile {
                margin-top: 40px;
            }
        }

        .Button {
            @include mobile {
                font-size: 15px;
                height: 55px;
                line-height: 55px;
            }
        }
    }
}

@include desktopAndAbove {
    .MyAccountData {
        grid-row-gap: 40px;

        &-AddressList {
            grid-gap: 56px;
        }

        &-CustomerData {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 24px;
            align-items: flex-start;

            .Button {
                --button-padding: 36px;

                width: auto;
            }
        }
    }
}
