@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.MyAccountFinancialDocument {
    $parent: &;

    display: grid;
    grid-row-gap: 64px;

    &-Table {
        table-layout: auto;
        width: 100%;
        margin-block-end: 0;
        border: 0;

        th {
            color: $black;
        }
    }

    &-Tooltip {
        display: inline-flex;
        align-items: center;
        cursor: pointer;
        color: $color-secondary-70;

        .Icon {
            margin-inline-end: 4px;
        }

        &:hover+* {
            visibility: visible;
        }
    }

    &-Message {
        padding-inline: 16px;
        padding-block: 8px;
        background-color: $white;
        color: $color-secondary-70;
        border: 1px solid $color-secondary-70;
        z-index: 1;
        visibility: hidden;
    }

    &-Reference {
        a {
            color: $color-neutral-90;
        }
    }

    th.MyAccountFinancialDocument-Reference,
    td.MyAccountFinancialDocument-Reference {
        text-align: center;
    }

    .Table-Row {
        th {
            svg {
                position: absolute;
                inset-block-start: 50%;
                transform: translateY(-50%);
                cursor: pointer;
                height: 20px;
                width: 20px;
            }
        }
    }
}
