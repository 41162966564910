@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.MyAccountCustomerPopup {
    .Popup {
        &-Content {
            max-width: 559px;
            width: 90%;

            .Field {
                &-Label {
                    padding-bottom: 8px;
                }

                input {
                    background-color: $color-neutral-20;
                    padding: 14px 0 13px 32px;

                    ::placeholder {
                        color: $color-neutral-10;
                    }
                }

                @include mobileAndTablet {
                    input {
                        padding: 14px 32px 13px 32px;
                    }
                }
            }

            .Button {
                width: 218px;
                height: 55px;
                margin-top: 12px;

                @include mobile {
                    width: 100%;
                }

                @include tabletAndAbove {
                    width: 220px;
                }
            }

            &::after {
                display: none;
            }
        }

        &-Heading {
            margin-bottom: 32px;
        }

        &-CloseBtn {
            inset-inline-end: 0;
            inset-block-start: 0;

            .CloseIcon {
                height: 24px;
                width: 24px;
            }
        }
    }
}
