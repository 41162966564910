@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

:root {
    --wishlist-item-border-color: var(--expandable-content-divider-color);
    --myaccount-wishlist-action-bar-offset: -83px;
}

.MyAccountMyWishlist {
    &-Products {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-auto-rows: max-content;
        grid-gap: 24px;
    }

    .ProductCard-Name {
        min-height: 65px;
    }

    &-ActionBar {
        display: flex;
        padding-bottom: 32px;
    }

    &-ActionBarActionWrapper {
        display: flex;
        justify-content: space-between;
        margin-block-end: 10px;
    }

    .Button {
        &.MyAccountMyWishlist-ClearWishlistButton:not([disabled]) {
            padding: 0;
        }
    }

    &-NoProductsButton {
        margin-block-start: 12px;

        @include mobile {
            margin-block-start: 14px;
        }
    }

    &-Button {
        .CartIcon {
            fill: var(--color-white);
            margin-inline-end: 12px;
            width: 20px;
            height: 20px;

            &:hover {
                fill: var(--color-white);
            }
        }
    }

    &-ClearRemoveItemsButton {
        text-transform: uppercase;
        color: initial;

        &.Button {
            width: auto;
        }
    }

    &-ShareWishlistButton {
        min-width: 164px;

        svg {
            fill: $color-primary-50;
        }
    }
}

@include mobile {
    .MyAccountMyWishlist {
        &-Products {
            grid-column-gap: 28px;
            border: 0;
            max-width: 100%;
            margin: 0 auto;
            margin-block-end: var(--myaccount-wihslist-products-margin-bottom);
            padding-top: 0;
        }

        &-ActionBar {
            background-color: var(--my-account-content-background);
            inset-block-end: var(--footer-total-height);
            flex-wrap: wrap;
            justify-content: space-between;
            inset-inline-start: 0;
            padding: 14px 14px;
            position: fixed;
            width: 100%;
            border-block-start: 1px solid var(--primary-divider-color);
            z-index: 10;
            bottom: 0;
        }

        .Button {
            &.MyAccountMyWishlist-ShareWishlistButton {
                order: 3;
                margin-top: 16px;
                padding: 0;
                width: 50% !important;
            }

            &.MyAccountMyWishlist-Button {
                flex: 0 0 100%;
                order: 1;
                margin-top: 0;
            }

            &.MyAccountMyWishlist-ClearWishlistButton {
                width: 50%;
                order: 2;
            }
        }

        .WishlistItem {
            &-Content {
                h4 {
                    text-align: left;
                    font-size: 12px;
                    line-height: 18px;
                    margin-bottom: 8px;
                    font-weight: 600;
                    margin-top: 0;
                }
            }

            &-ProductCard {
                .ProductCard-Price {
                    align-items: flex-start;

                    span {
                        text-align: left;
                    }
                }
            }

            &-Price {
                text-align: left;
            }
        }

        .WishlistItemOptions-List {
            text-align: left;
        }
    }
}

@include mobileAndTablet {
    .MyAccountMyWishlist {
        .Button {
            &.MyAccountMyWishlist-ShareWishlistButton {
                padding: 0;
                min-width: 55px;
                max-width: 55px;
            }
        }
    }
}

@include tablet {
    .MyAccountMyWishlist {
        &-Products {
            padding-block-end: var(--myaccount-wishlist-action-bar-offset);
        }

        &-NoProducts {
            position: relative;
            top: 60px;
        }
    }
}

@include tabletAndAbove {
    .MyAccountMyWishlist {
        &-Products {
            grid-template-columns: repeat(2, minmax(0, 1fr))
        }
    }
}

@include desktop {
    .MyAccountMyWishlist {
        &-ActionBar {
            display: flex;
            justify-content: space-between;

            .Button {
                padding-left: 20px;
                padding-right: 20px;
                width: auto;
            }
        }

        .Button {
            + .Button {
                &.MyAccountMyWishlist-Button,
                &.MyAccountMyWishlist-ClearWishlistButton {
                    margin-top: 0;
                    margin-left: 24px;
                    width: 175px;
                    max-width: 100%;
                }
            }

            &.MyAccountMyWishlist-ShareWishlistButton {
                margin-top: 0 !important;
            }
        }
    }
}

@include above1480 {
    .MyAccountMyWishlist {
        &-Products {
            margin-top: 75px;
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }

        &-ActionBar {
            position: absolute;
            top: -120px;
            right: 0;
        }
    }
}
