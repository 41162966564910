@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

:root {
    --filter-button-backgroud: #{$black};
    --filter-button-hover-backgroud: #{$color-primary-50};
    --filter-button-border: #{$black};
    --filter-button-hover-border: #{$color-primary-50};
}

.MyAccountFilter {
    border: 1px solid $color-other-9;

    @include desktop {
        display: grid;
        grid-template-columns: 1fr minmax(auto, 234px);
    }

    &-Toggle {
        grid-column: 1/-1;

        & > button {
            display: flex;
            align-items: center;
            font-weight: 600;
            font-size: 13px;
            line-height: 20px;
            color: $color-primary-30;
            text-transform: uppercase;
            cursor: pointer;

            @include mobile {
                color: $color-neutral-90;
                font-size: 14px;
                font-weight: 500;
            }

            svg {
                height: 20px;
                width: 20px;

                @include mobile {
                    margin-left: 10px;
                }

                path {
                    fill: $color-primary-30;

                    @include mobile {
                        fill: $color-neutral-90;
                    }
                }
            }
        }
    }

    &-Custom {
        font-weight: 400;
        font-size: 13px;
        line-height: 17px;

        @include mobile {
            font-size: 14px;
        }

        &Content {
            display: flex;
            align-items: center;
            margin-block-start: 11px;

            @include mobile {
                margin-top: 5px;
            }

            span {
                width: 27px;
                min-width: 27px;
                text-align: center;
            }

            .Icon {
                min-width: 16px;
                margin-inline-end: 16px;
                color: $color-primary-50;

                @include mobile {
                    order: 1;
                    margin-right: 0;
                    margin-left: 16px;
                }
            }
        }
    }

    &-Collapse {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        row-gap: 24px;
        column-gap: 64px;

        @include desktop {
            row-gap: 36px;
        }

        &:not(&_isOpen) {
            @include visually-hidden;
        }
    }

    &-Content {
        display: grid;
        row-gap: 24px;
        padding-inline: 24px;
        padding-block: 24px;

        @include mobile {
            background: $color-other-7;
        }

        @include desktop {
            row-gap: 36px;
            padding-inline: 32px;
        }

        .Field {
            margin-block: 0;

            &-Label {
                font-weight: 400;
                font-size: 13px;
                line-height: 20px;
                padding-block-end: 8px;

                @include mobile {
                    font-size: 14px;
                    padding-bottom: 5px;
                }
            }

            &Group {
                display: grid;
                grid-gap: 16px;
                margin-block-start: 8px;

                @include tabletAndAbove {
                    grid-template-columns: repeat(2, 1fr);
                    grid-gap: 32px;
                }
            }

            &Select {
                --input-background-color: #{$white};
                --input-padding: 8px 16px;

                &-Arrow {
                    right: 8px;
                }

                &-Select {
                    --input-border-color: #{$color-neutral-20};

                    padding: var(--input-padding);
                    padding-inline-end: 36px;
                    height: 40px;
                }

                &-Option {
                    padding: var(--input-padding);
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 20px;
                    margin-block: 0;
                }
            }

            input,
            select {
                --input-background-color: #{$white};
                --input-padding: 8px 16px;

                min-height: 40px;
                width: 100%;
                font-weight: 400;
                font-size: 13px;
                line-height: 20px;

                &[type='radio'] + .input-control {
                    margin-inline-end: 8px;
                }
            }
        }

        fieldset {
            & > legend {
                font-weight: 400;
                font-size: 13px;
                line-height: 17px;
            }
        }
    }

    &-Action {
        --button-background: var(--filter-button-backgroud);
        --button-hover-background: var(--filter-button-hover-backgroud);
        --button-border: var(--filter-button-border);
        --button-hover-border: var(--filter-button-hover-border);

        display: flex;
        align-items: center;
        background: $color-other-7;
        padding-inline: 24px;
        padding-block: 24px;

        @include desktop {
            border-left: 1px solid $color-other-9;
        }

        @include mobile {
            padding: 0 24px 38px;
        }

        & > .Button {
            width: 100%;
            height: 40px;

            @include mobile {
                font-size: 14px;
                height: 48px;
            }
        }
    }
}
