@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.MyAccountMyOrders {
    $parent: &;

    display: grid;
    grid-row-gap: 64px;

    @include mobile {
        grid-row-gap: 29px;
    }

    &-Heading {
        font-size: 24px;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0px;
    }

    &-Table {
        table-layout: auto;
        width: 100%;
        margin-block-end: 0;
        border: 0;

        th {
            color: $black;
            background: $color-other-7;
            font-size: 14px;
            vertical-align: middle;
            padding: 6px 24px 8px;
            text-align: left;
            min-width: 140px;

            @include mobile {
                padding: 12px 24px 14px;
            }

            + th {
                padding-left: 0;
            }

            &.MyAccountMyOrders-NumberOfItems,
            &.MyAccountMyOrders-Reorder {
                text-align: center;
            }
        }

        th,
        td {
            max-width: 160px;
            white-space: break-spaces;
            word-wrap: break-word;

            @include mobile {
                padding: 11px 24px;
            }
        }
    }

    .Table {
        &-Wrapper {
            @include mobile {
                padding-bottom: 40px;

                &::-webkit-scrollbar {
                    height: 4px;
                }

                &::-webkit-scrollbar-track {
                    background-color: $color-other-9;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: $color-neutral-90;
                }
            }
        }
    }
}
