@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.MyAccountContractorUserList {
    font-size: 14px;

    &-AddUser {
        @include desktopAndAbove {
            position: absolute;
            right: 0;
            top: 0;
        }

        @include mobileAndTablet {
            margin-block-end: 32px;
        }
    }

    &-Row_isAdmin {
        background-color: $color-primary-50;
        color: $color-neutral-5;
    }

    td.MyAccountContractorUserList-Actions,
    th.MyAccountContractorUserList-Actions {
        text-align: center;
    }

    &-UserList {
        padding: 16px;
        border-bottom: 1px solid $color-other-9;

        &_isAdmin {
            background-color: $color-primary-50;
            border: 0;

            p {
                color: $color-neutral-5;
            }
        }

        p {
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            letter-spacing: 0.02em;

            span {
                @include mobile {
                    font-weight: 500;
                }
            }
        }
    }

    &-EditUser {
        display: flex;
        gap: 10px;
        align-items: center;
        margin-top: 20px;

        p {
            margin-bottom: 0;

            @include mobile {
                padding-bottom: 4px;
                font-weight: 500;
            }
        }
    }
}
