@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.MyAccountTabListItem {
    &-Button {
        font-size: 16px;
        color: $color-neutral-70;
        width: 100%;
        padding-left: 56px;

        svg {
            position: absolute;
            left: 24px;
            top: 50%;
            transform: translateY(-50%);
            max-width: 20px;
        }
    }

    &_isActive, &:hover {
        button {
            &.MyAccountTabListItem {
                &-Button {
                    color: $black;
                    font-weight: 400;
                }
            }
        }
    }
}

