@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.MyAccountContractorUserCreate {
    &-Form {
        @include desktopAndAbove {
            max-width: 442px;
        }

        .Field {
            &Form-Fields {
                margin-block-end: 37px;
            }

            &-Label {
                font-size: 14px;
                line-height: 21px;
            }

            &Select {
                &-Select {
                    height: 50px;
                }

                &-Option {
                    margin-block: 0;
                    padding-block: 14px;
                    padding-inline: 32px;
                }
            }
        }

        .Button {
            @include desktopAndAbove {
                width: 209px;
            }
        }
    }
}
