@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.MyAccountCustomerTable {
    display: grid;
    grid-gap: 24px;
    grid-template-columns: 1fr;

    a {
        color: $color-primary-50;
    }

    @include mobile {
        grid-gap: 28px;
    }

    &-Button {
        @include desktop {
            position: absolute;
            inset-block-start: -55px;
            inset-inline-end: 0;
        }
    }

    &-Table {
        font-size: 14px;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
    }


    .Field {
        &-CheckboxLabelContent {
            font-size: 13px;
            color: $color-primary-90;
        }
    }
}
