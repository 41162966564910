@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.MyAccountCreateAccount {
    p {
        display: inline;
        color: $color-neutral-70;
    }


    label.Field-CheckboxLabel {
        margin-bottom: 10px;
    }
}

.CreateAccount {
    &-InnerWrapper {
        > div {
            margin: 0;
        }
    }

    .MyAccountOverlay {
        &-PersonalInfoLegend {
            padding-block: 8px;
        }

        &-Terms {
            margin-top: 25px;
        }

        &-SignUpLegend {
            padding-block: 28px 8px;
        }

        &-Checkbox {
            display: grid;
            grid-template-columns: 25px 1fr ;



            & p {
                font-size: 14px;
                margin: 0;
                letter-spacing: 0.5px;
                color: $color-neutral-50;
                min-height: 0;
            }

            & a {
                color: $color-primary-90;

                &:hover {
                    color: $color-neutral-90;
                }
            }

            .Field-ErrorMessages {
                width: 250px;
            }
        }

        &-SignInButton {
            height: fit-content;
        }

        &-PasswordBlock {
            @include wide-desktop {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-column-gap: 16px;
            }
        }

        &-Actions {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 14px;
        }

        &-AdditionalLabel {
            color: $color-neutral-70;
        }
    }
}

@include mobile {
    .CreateAccount {
        .MyAccountOverlay {
            &-Actions {
                display: block;
            }

            &-AdditionalActions {
                text-align: center;
                margin-top: 32px;
            }
        }
    }
}
