@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.MyAccountCompanyStructure {
    display: grid;
    grid-template-columns: 40% 50%;
    justify-content: space-between;

    &-Heading {
        margin-block: 0 5px;
        font-weight: 400;
        font-size: 24px;
        line-height: 1.4;
        margin-bottom: 16px;

        &_departments {
            @include mobile {
                margin-top: 42px;
            }
        }
    }

    &-Substructures {
        margin-top: 20px;
        font-size: 14px;
    }

    &-Dropdown {
        width: 60px;

        svg {
            display: block;
            margin-left: 4px;

            @include mobile {
                margin-left: 12px;
            }

            &:hover {
                cursor: pointer;
            }
        }
    }

    &-CellAdditional {
        margin-top: 12px;

        p {
            margin-bottom: 0;
            line-height: 20px;
            color: $color-neutral-70;
            font-size: 13px;
        }
    }

    &-Cell {
        line-height: 20px;
    }

    .KeyValueTable {
        th,
        td {
            padding-block: 8px;
            padding-inline-start: 0;
        }
    }

    .Table {
        &-Wrapper {
            margin-bottom: 30px;
        }

        &-Table {
            table-layout: fixed;

            td {
                padding: 12px 0 12px;
                vertical-align: top;
            }
        }
    }
}

@include mobile {
    .MyAccountCompanyStructure {
        grid-template-columns: 1fr;

        .KeyValueTable {
            border: var(--table-body-border);

            th,
            td {
                padding: 12px 24px;
                padding-inline-start: 24px;
            }
        }

        &-Substructures {
            .Table {
                &-Table {
                    .Table-Row {
                        display: block;
                        padding-block: 10px;
                        border-bottom: var(--table-body-border);

                        &_isActive {
                            margin-top: 16px;
                            border-bottom: 0;
                        }

                        &:first-of-type {
                            border-top: var(--table-body-border);
                        }
                    }

                    .Table-Cell {
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                        width: 100%;
                        padding-left: 40px;
                        padding-block: 6px;

                        span {
                            padding-right: 8px;
                            font-weight: 500;
                        }

                        p {
                            margin: 0;
                        }

                        &.MyAccountCompanyStructure-Dropdown {
                            position: absolute;
                            left: -16px;
                            padding-left: 0;
                            z-index: 1;

                            .MyAccountCompanyStructure {
                                &-Cell {
                                    &_isActive {
                                        width: auto;
                                        margin-top: 0;
                                    }
                                }
                            }
                        }

                        .MyAccountCompanyStructure {
                            &-Cell {
                                &_isActive {
                                    width: 100%;
                                    margin-top: 8px;
                                }
                            }

                            &-CellAdditional {
                                margin-top: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
