@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.MyAccountAddressTable {
    .KeyValueTable {
        font-size: 13px;

        th {
            @include tabletAndAbove {
                width: 250px;
            }
        }

        &-Heading {
            font-size: 14px;
            font-weight: 500;
        }
    }
}
