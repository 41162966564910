@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

:root {
    --my-account-content-background: #{$white};
    --my-account-wrapper-margin-mobile: 18px;
    --my-account-content-border: 1px solid #{$color-neutral-30};
}

.MyAccount {
    background: var(--my-account-background);

    &-Wrapper {
        padding-block-start: 12px;
        padding-block-end: 56px;
        --content-wrapper-width: #{$page-max-width-cms};
    }

    &-TabContent {
        background: var(--my-account-content-background);
        overflow: visible;
    }

    &-Heading {
        text-transform: none;
        margin: 0;
        margin-block-end: 24px;
        font-size: 30px;
        line-height: 42px;
        font-weight: 400;
    }

    &-SubHeading {
        color: var(--secondary-dark-color);
        font-size: 16px;
    }

    &-SectionHeading {
        font-size: 24px;
        margin-block: 0 24px;
        font-weight: 400;
    }
}

@include mobile {
    .MyAccount {
        .Button {
            &_size_small {
                font-size: 15px;
            }
        }
    }
}

@include mobileAndTablet {
    .MyAccount {
        &-Heading {
            display: none;
        }

        .Button {
            width: 100%;

            +.Button {
                margin-top: 16px;
            }
        }
    }
}

@include tabletAndAbove {
    .MyAccount-Wrapper {
        padding-block-end: 64px;
    }
}

@include desktopAndAbove {
    .MyAccount {
        &-Wrapper {
            display: grid;
            grid-template-columns: auto 1fr;
            grid-template-rows: auto 1fr;
            grid-row-gap: 0;
            grid-column-gap: 24px;
            padding-block-end: 100px;
        }

        &-TabContent {
            grid-row: span 2;
        }

        .Button {
            +.Button {
                margin-top: 0;
                margin-left: 32px;
            }
        }
    }
}
