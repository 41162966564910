@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.MyAccountOrderPopup {
    --popup-content-padding: 24px 14px;

    &-ProductsWrapper {
        h4 {
            border-bottom: 0;
        }
    }

    &-Products {
        margin-block: 0;


        .Table-Wrapper {
            max-height: 500px;

            .Table-Table {
                direction: ltr;
            }

            &::-webkit-scrollbar {
                width: 5px;
            }

            &::-webkit-scrollbar-track {
                background-color: $color-other-12;
            }

            &::-webkit-scrollbar-thumb {
                background-color: $color-neutral-90;
            }
        }

        thead {
            position: sticky;
            top: 0;
            z-index: 1;
        }

        & td {
            vertical-align: top;

            &:nth-child(5) {
                text-align: center;
            }
        }
    }


    &-Product {
        display: grid;
        grid-template-columns: 64px auto;
        grid-column-gap: 16px;

        &Name {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            margin-block-end: 8px;
            width: 175px;
        }

        &Sku {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: $color-other-8;
        }

        &Price {
            display: inline-flex;
            align-items: flex-end;
            column-gap: 8px;

            &List {
                display: inline-grid;
            }

            &Value,
            &Label {
                font-weight: 400;
                font-size: 12px;
                line-height: 20px;
            }

            &Label {
                color: $color-other-8;
                min-width: 36px;
            }

            &_net &Value {
                font-weight: 600;
            }
        }
    }

    &-Header {
        background: $color-other-7;
        font-weight: 600;
        font-size: 13px;
        line-height: 19px;
        padding-inline: 24px;
        padding-block: 14px;
        color: $black;
    }

    &-Address {
        margin-block: 0;
    }

    &-BaseInfo,
    &-ShippingWrapper,
    &-ShippingInfo,
    &-DeliveryTracking {
        margin-block-end: 40px;
    }

    &-OrderWrapper {
        margin-top: 32px;

        dl {
            grid-template-columns: repeat(2, 180px);
            margin-bottom: 0;
        }
    }

    &-ShippingInfo {
        display: grid;
        grid-column-gap: 16px;
        grid-row-gap: 40px;

        @include tabletAndAbove {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    &-Content {
        grid-gap: 0;
        padding: var(--popup-content-padding);

        @include tabletAndAbove {
            padding-block: 32px;
            padding-inline: 32px;
        }

        h4 {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
        }

        dl {
            margin-block-end: 0;
            margin-top: 16px;
            grid-gap: 16px;

            @include tabletAndAbove {
                grid-template-columns: 33% 33%;
            }
        }

        dt, dd {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: $black;
        }

        dt {
            color: $color-neutral-70;
            font-weight: 400;
        }

        .KeyValueTable {
            th.KeyValueTable-Heading {
                font-weight: 600;
                font-size: 13px;
                line-height: 19px;
                padding-inline: 24px;
                padding-block: 14px;
                color: $black;
            }

            th, td {
                font-weight: 400;
                font-size: 13px;
                line-height: 19px;
                padding-inline: 24px;
                padding-block: 12px;
            }

            th {
                font-weight: 500;
                color: $color-neutral-70;
            }
        }
    }

    &-Totals {
        display: grid;
        justify-items: flex-end;
        justify-self: flex-end;

        &Wrapper {
            display: grid;
            grid-gap: 24px;
            padding-inline: 24px;
            padding-block: 24px 0;

            @include tabletAndAbove {
                grid-template-columns: repeat(2, 1fr);
            }

            h4 {
                font-weight: 400;
                font-size: 24px;
                line-height: 32px;
            }
        }
    }

    &-Total {
        display: flex;
        align-items: flex-end;
        column-gap: 16px;
        font-weight: 400;
        font-size: 24px;
        line-height: 24px;

        &_net {
            color: $color-other-4;
        }

        &Label {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: $color-neutral-50;
        }

        &:not(&:last-child) {
            margin-block-end: 12px;
        }
    }

    &-Actions {
        background: $color-other-7;
        padding: var(--popup-content-padding);
        padding-block-start: 0;
        display: flex;
        align-items: center;

        @include tabletAndAbove {
            padding-block: 0 32px;
            padding-inline: 32px;
        }
    }

    &-Reorder, &-DownloadInvoice {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        padding-inline-end: 24px;

        svg {
            margin-inline-end: 12px;
            fill: $color-primary-90;
        }

        &[disabled] {
            color: $color-neutral-50;
            cursor: not-allowed;

            svg path {
                fill: $color-neutral-50;
            }
        }
    }

    &-DownloadInvoice {
        padding-inline-start: 24px;
        border-inline-start: 1px solid $color-other-13;
    }

    &-Comments {
        margin-block-start: 8px;
    }

    &-Comment {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        margin-block-end: 8px;
        color: $color-neutral-70;

        &:last-child {
            margin-block-end: 0;
        }
    }

    .Popup {
        &-Content {
            padding-block: 0;
            padding-inline: 0;

            &::after {
                display: none;
            }

            &::-webkit-scrollbar {
                width: 5px;
            }

            &::-webkit-scrollbar-track {
                background-color: $color-other-12;
            }

            &::-webkit-scrollbar-thumb {
                background-color: $color-neutral-90;
            }
        }

        &-Heading {
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;
            margin-block-end: 0;
            color: $color-neutral-70;

            span {
                color: $black;
            }
        }

        &-CloseBtn {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 48px;
            height: 48px;
            inset-inline-end: 16px;
            inset-block-start: 16px;
            background: $color-neutral-90;

            .CloseIcon {
                fill: $color-neutral-5;
            }
        }

        &-Header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: var(--popup-content-padding);
            background: $color-other-7;

            @include tabletAndAbove {
                padding-block: 32px 24px;
                padding-inline: 32px;
            }
        }
    }
}
