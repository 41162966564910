@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.MyAccountDashboard {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 32px;

    .KeyValueTable {
        margin-block: 0;
        margin-bottom: 0;
    }

    &-Orders {
        .MyAccountMyOrders {
            margin-block-end: 40px;
        }

        .Button {
            color: var(--button-color);

            @include mobile {
                height: 48px;
                line-height: 48px;
                font-size: 14px;
            }
        }
    }

    &-CustomerData,
    &-AddressData {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 32px;

        .MyAccountCustomerTable {
            &-Actions {
                margin-block-start: 0;
            }
        }

        .KeyValueTable {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;

            &-Heading {
                font-weight: 500;
                line-height: 20px;
            }

            .Field {
                margin-block: 0;
            }
        }

        .Button {
            @include mobile {
                font-size: 15px;
                height: 55px;
                line-height: 55px;
            }
        }
    }

    &-Info {
        display: inline-block;
        margin-top: 40px;

        &:not(:last-child) {
            margin-block-end: 5px;
        }

        a {
            color: $white;
            padding: 10px 32px;
            font-size: 14px;
            text-align: center;

            @include mobile {
                height: 48px;
                line-height: 48px;
            }

            @include mobileAndTablet {
                width: 100%;
            }
        }

        @include mobileAndTablet {
            display: flex;
        }
    }
}

@include desktopAndAbove {
    .MyAccountDashboard {
        grid-row-gap: 40px;

        &-CustomerData,
        &-AddressData {
            grid-template-columns: 1fr 1fr;
            grid-gap: 24px;
            align-items: flex-start;

            .Button {
                --button-padding: 36px;

                width: auto;
            }
        }
    }
}
